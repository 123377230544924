import React from 'react'

const BatchCTA = (props) => {
    return(
        <button type="button" onClick={() => window.location.href = `${props.href ? props.href : '/start'}` }
                className={`w-full text-${props.fontSize ? props.fontSize : "base"} px-${props.px ? props.px : '5'} py-${props.py ? props.py : '3'} mt-${props.mt ? props.mt : "3"} font-semibold leading-6 text-white transition duration-150 ease-in-out border border-l-0 border-r-0 border-transparent rounded-md shadow-sm focus:outline-none sm:mt-0 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto bg-gradient-to-r to-peach-500 from-indigo-700 hover:from-peach-500 hover:to-indigo-700`}>
            {props.content ? props.content : 'Get Started'}
        </button>
    )
}

export default BatchCTA