import React from 'react'
import Footer from './footer'
import Header from './header'

const Layout = (props) => {
    return (
        <body className="flex flex-col h-screen min-h-sceen">
            { props.header === false ? "" : <Header/> }
                <main className="flex-grow">
                    <div className={`mx-auto ${props.container === false ? '' : 'container px-4 sm:px-6 lg:px-8 py-8 md:py-4'}`}>
                        {props.children}
                    </div>
                </main>
                { props.footer === false ? "" : <Footer/> }
        </body>
    )
}

export default Layout