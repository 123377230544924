import { Link } from 'gatsby'
import React from 'react'
import shopifyAppStoreLogoWhite from '../../public/Shopify-App-Store-Badge-Final-White.svg'
import BatchCTA from './batchCta'
import TextLogo from '../../public/batch-text-logo.svg'
const Header = () => {

    const menuLiClass = 'font-medium text-white transition duration-150 ease-in-out hover:text-gray-300 indigo-border-on-hover'
    const activeMenuLiClass = "border-b-peach text-white"

    const trackClick = () => {
        if (typeof document !== 'undefined') {
            window.analytics.track('Click to App Store', {
                source: window.location.href
            });
            window.open('https://apps.shopify.com/batch-offers', '_blank');
        }
    }
    return (
        <header>
            <div className="relative overflow-hidden bg-gray-900">
                <div className="hidden sm:block sm:absolute sm:inset-0">
                    <svg className="absolute bottom-0 right-0 mb-48 text-gray-700 transform translate-x-1/2 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0" width="364" height="384" viewBox="0 0 364 384" fill="none">
                    <defs>
                        <pattern id="eab71dd9-9d7a-47bd-8044-256344ee00d0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="364" height="384" fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
                    </svg>
                </div>
            
                <div className="relative pt-6 pb-12 bg-transparent sm:pb-8">
                    <nav className="relative flex items-center justify-between max-w-screen-xl px-4 mx-auto sm:px-6">
                        <div className="flex items-center flex-1">
                            <div className="flex items-center justify-between md:w-auto">
                                <Link to="/" aria-label="Home">
                                    <img className="w-auto h-6 sm:h-8" src={TextLogo} alt="Batch Logo"/>
                                </Link>
                            </div>
                            <div className="flex ml-10 space-x-5 md:space-x-10">
                                {/* <Link to="/product" className={menuLiClass} activeClassName={activeMenuLiClass}>Product</Link>
                                <Link to="/features" className={menuLiClass} activeClassName={activeMenuLiClass}>Features</Link> */}
                                <Link to="/pricing" className={menuLiClass} activeClassName={activeMenuLiClass}>Pricing</Link>
                                <Link to="/blog" className={menuLiClass} activeClassName={activeMenuLiClass}>Blog</Link>
                                <a href="https://help.sellwithbatch.com" className={menuLiClass} activeClassName={activeMenuLiClass}>Support</a>
                                <Link to="/request-demo" className={menuLiClass} activeClassName={activeMenuLiClass}>Request demo</Link>
                            </div>
                        </div>
                        <div className="flex ml-10 space-x-5 md:space-x-10">
                            <a className="inline-flex items-center cursor-pointer" onClick={() => trackClick()}>
                                <img alt="Get Batch on the Shopify App Store" src={shopifyAppStoreLogoWhite} className="w-40" />
                            </a>
                            <BatchCTA content="Get Started Free" />
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Header